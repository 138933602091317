
import { Component, Vue } from 'vue-property-decorator'
import MapLocation from '@/components/mapLocation/Index.vue'
import { TableData, ResourcesLocation } from '@/types/dispatch'
import UploadFile from '@/components/uploadFile/Index.vue'

@Component({
  components: { MapLocation, UploadFile }
})

export default class DispatchDetail extends Vue {
  private infoData: TableData = {
    dispatchId: '',
    projectId: '',
    objectId: '',
    dispatchGrade: '',
    dispatchGradeName: '',
    dispatchType: '',
    dispatchTypeName: '',
    dispatchPosition: '',
    dispatchState: '',
    dispatchSdescribe: '',
    reportperson: '',
    contactInformation: '',
    region: '',
    longitude: '',
    latitude: '',
    verify: '',
    resultDescription: '',
    isDel: 0,
    ctime: ''
  }

  private resourcesLocationList: ResourcesLocation[] = []

  private submitShow = false

  private rules = {
    scenariosId: [
      { required: true, message: '请选择预案', trigger: 'change' }
    ],
    dispatchState: [
      { required: true, message: '请选择处置进度', trigger: 'change' }
    ]
  }

  private areaList = []
  private disposeList = []
  private scenariosDetail = {}

  created () {
    this.getDisposeList()
    this.getDetail()
  }

  // 预案列表
  getDisposeList () {
    this.$axios.get(this.$apis.dispatch.selectDispatchScenariosByList).then(res => {
      this.disposeList = res.list || []
    })
  }

  getDetail () {
    this.$axios.get(this.$apis.dispatch.selectDispatchByDispatchId, {
      dispatchId: this.$route.params.id
    }).then(res => {
      if (res) {
        this.infoData = res
        if (res.verifyUserId) {
          this.infoData.dispatchHandleList.unshift({
            ctime: res.verifyTime,
            createUserName: res.verifyUserName,
            content: res.resultDescription
          })
        }
        this.resourcesLocationList = [{
          amount: 1,
          longitude: res.longitude,
          latitude: res.latitude
        }]
        res.scenariosId && this.getScenariosDetail(res.scenariosId)
      }
    })
  }

  // 预案匹配
  getScenariosDetail (scenariosId: string) {
    this.$axios.get(this.$apis.scenarios.selectDispatchScenariosByScenariosId, {
      scenariosId: scenariosId
    }).then(res => {
      this.scenariosDetail = res || {}
    })
  }
}
